export default {
  profile: {
    info: {
      male: 'He/him',
      female: 'She/her',
    },
    preferences: {
      genderSetToast: "To appear in the rankings your pronoun was set to 'Male'.",
      anonymousSetToast: 'You are shown as anonymous: rankings require a pronoun.',
    },
  },
  competitions: {
    genderRequiredMsg: 'Please, specify pronoun to participate',
    setGender: 'Set pronoun',
  },
}
